@use '@/styles/utils/mixins.scss' as *;

@use '@/styles/utils/swiper.scss';

.fancySectionWrap {
  position: relative;
  padding: 30px 0;
  &::before {
    content: '';
    background: linear-gradient(180deg, #05141f 0%, rgba(0, 0, 0, 0) 100%),
      rgba(5, 20, 31, 0.1);
    backdrop-filter: blur(2.5px);
    position: absolute;
    width: 100%;
    height: 50%;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }
  &::after {
    content: '';
    background-color: $kiaPolarWhite;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    width: 100%;
    height: 53%;
    @media screen and (max-width: 991.98px) {
      height: 53%;
    }
    @media screen and (max-width: 768px) {
      height: 57%;
    }
    @media screen and (max-width: 767px) {
      height: 60%;
    }
  }
  .fancyBannerBg {
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 50%;
    z-index: -2;
    @media screen and (max-width: 576px) {
      background-position: 50% 50%;
      background-attachment: fixed;
    }
  }
  .headingElement {
    position: relative;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'KiaSignature';
      font-weight: 600;
      font-style: normal;
      &.titleXl {
        font-size: 60px;
        line-height: 60px;
        font-weight: 600;
        @include tab() {
          font-size: 48px;
          line-height: 60px;
        }
        @include mob() {
          font-size: 36px;
          line-height: 43.2px;
        }
      }
      &.titleLg {
        font-size: 48px;
        line-height: 60px;
        @include tab() {
          font-size: 36px;
          line-height: 43.2px;
        }
        @include mob() {
          font-size: 24px;
          line-height: 28.8px;
        }
      }
      &.titleDealer {
        color: $kiaMidnightBlack !important;
      }
      &.titleXs {
        font-size: 16px;
        line-height: 19.2px;
      }
    }
  }
  .filterBtn {
    min-width: fit-content;
  }
  .fancySectionHeader {
    .headingElement {
      .titleLg {
        color: $kiaPolarWhite;
        @include tab() {
          font-size: 36px;
          line-height: 43.2px;
        }
        @include mob() {
          font-size: 24px;
          line-height: 36.2px;
        }
      }
    }
    .filterBtn {
      min-width: fit-content;
    }
  }
  .filterChip {
    margin: 12px 12px 12px 0;
  }
  .fancyBannerContent {
    .ctaBtn {
      width: 100%;
    }
    .labelText {
      font-size: 14px;
      line-height: 19.6px;
      color: $kiaLabelGrey;
      margin-bottom: 8px;
      @include mob() {
        font-size: 12px;
        line-height: 14.4px;
      }
    }
    .lableBigText {
      font-size: 18px;
      line-height: 22px;
      font-weight: 600;
      color: $kiaMidnightBlack;
      margin-bottom: 8px;
      @media screen and (max-width: 480px) {
        font-size: 16px;
        line-height: 19.2px;
      }
    }
    .priceText {
      min-width: max-content;
    }
    .labelSmallText {
      font-size: 12px;
      line-height: 14.4px;
      color: $kiaLabelGrey;
    }
  }
  &.noGradient {
    &::before {
      background: none;
    }
  }
  &.tabSliderSection {
    color: $kiaPolarWhite;
    padding-top: 75px;
    min-height: calc(100vh - 83px);
    justify-content: center;
    &::before,
    &::after {
      background: none;
    }
    .mobileSwiperControlsWrap {
      width: 100%;
      flex: 0 0 auto;
      flex-direction: row;
      align-items: flex-end;
      @media screen and (max-width: 991.98px) {
        align-items: flex-start;
      }
    }
    .fancyBannerContent {
      .ctaBtn {
        width: 100%;
      }
      .labelText {
        color: $kiaSilver;
      }
      .lableBigText {
        color: $kiaPolarWhite;
      }
      .labelSmallText {
        font-size: 12px;
        line-height: 14.4px;
        color: $kiaSilver;
      }
    }
  }
}
.fancySectionWrapper {
  position: relative;
  .headingElement {
    position: relative;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'KiaSignature';
      font-weight: 600;
      font-style: normal;
      &.titleXl {
        font-size: 60px;
        line-height: 60px;
        font-weight: 600;
        @include tab() {
          font-size: 48px;
          line-height: 60px;
        }
        @include mob() {
          font-size: 36px;
          line-height: 43.2px;
        }
      }
      &.titleLg {
        font-size: 48px;
        line-height: 60px;
        @include tab() {
          font-size: 36px;
          line-height: 43.2px;
        }
        @include mob() {
          font-size: 24px;
          line-height: 28.8px;
        }
      }
      &.titleDealer {
        color: $kiaMidnightBlack !important;
      }
      &.titleXs {
        font-size: 16px;
        line-height: 19.2px;
      }
    }
  }
  .fancySwiper {
    width: 100%;
    max-width: 1366px;
    @media screen and (max-width: 1024px) {
      max-width: 100%;
      padding: 0px 6%;
    }
    @media screen and (max-width: 991.98px) {
      padding: 0px 16%;
    }
    @media screen and (max-width: 767px) {
      padding: 0px 32%;
    }
    @media screen and (max-width: 576px) {
      padding: 0px 0;
    }
    .swiper-wrapper {
      .swiper-container {
        position: relative;
        width: 100%;
        height: 300px;
        @media screen and (max-width: 991.98px) {
          height: 250px;
        }
        @media screen and (max-width: 768px) {
          height: 200px;
        }
      }
      .swiper-container > .swiper-slide__content {
        position: absolute;
        top: 0;
      }
      .swiper-slide {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 260px;
        transform: scale(0.7);
        transition: all 700ms linear;
        @media screen and (min-width: 992px) and (max-width: 1024px) {
          height: 230px;
        }
        @media screen and (max-width: 1024px) {
          transform: scale(1.1);
        }
        @media screen and (max-width: 991.98px) {
          justify-content: center;
          transform: scale(0.7);
        }
        @media screen and (max-width: 768px) {
          height: 200px;
          transform: scale(0.7);
        }
        @media screen and (max-width: 767px) {
          height: 175px;
          transform: scale(0.55);
        }
        &.swiper-slide-active {
          transform: scale(1.6);
          @media screen and (max-width: 1024px) {
            transform: scale(2.3);
          }
          @media screen and (max-width: 991.98px) {
            transform: scale(1.6);
          }
          @media screen and (max-width: 768px) {
            height: 200px;
          }
          @media screen and (max-width: 767px) {
            height: 175px;
            transform: scale(1.2);
          }
          @media screen and (max-width: 375px) {
            transform: scale(1.15);
          }
        }
      }
    }
  }
  .fancySwiperControls {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .swiper-navigation {
    display: flex;
    align-items: center;
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    @media screen and (min-width: 768px) {
      justify-content: space-between;
    }
    @media screen and (max-width: 1300px) {
      padding: 0 24px;
    }
  }
  .swiper-button-next-2,
  .swiper-button-prev-2 {
    width: 48px;
    height: 48px;
    margin-left: 0px;
    position: relative;
    z-index: 99;
    svg {
      height: 100%;
      width: 100%;
    }
  }
  .swiper-pagination {
    background-color: $keyline;
    border-radius: 15px;
    color: $kiaMidnightBlack;
    padding: 5px 10px;
    position: static;
    bottom: 0;
    right: 0;
    width: auto;
    left: auto;
    margin-right: 2px;
    line-height: 1;
    min-width: 48px;
    text-align: center;
    span {
      font-size: 12px;
      line-height: 14.4px;
    }
    @media screen and (min-width: 768px) {
      display: none;
    }
  }
}
.mobileSwiperControlsWrap {
  display: flex;
  .mobileSwiperControls {
    margin-left: auto;
    @media screen and (min-width: 768px) {
      display: none;
    }
    .fancySwiperControls {
      display: flex;
      align-items: center;
      .swiper-pagination {
        background-color: $keyline;
        border-radius: 15px;
        color: $kiaMidnightBlack;
        padding: 5px 10px;
        position: static;
        bottom: 0;
        right: 0;
        width: auto;
        left: auto;
        margin-right: 0px;
        line-height: 1;
        min-width: 48px;
        span {
          font-size: 12px;
          line-height: 14.4px;
        }
      }
      .swiper-navigation {
        font-size: 0;
      }
      .swiper-button-next-2,
      .swiper-button-prev-2 {
        width: 48px;
        height: 48px;
        margin-left: 3px;
        svg {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}
.tabSliderSection {
  .fancyBannerBg {
    height: 100%;
    background-position: bottom;
    background-attachment: initial;
  }

  .tabSwiperControls {
    margin-left: auto;
    margin-bottom: 1em;
    @media screen and (max-width: 767px) {
      display: none;
    }
    .fancySwiperControls {
      display: flex;
      align-items: center;
      .swiper-pagination {
        background-color: $keyline;
        border-radius: 15px;
        color: $kiaMidnightBlack;
        padding: 5px 10px;
        position: static;
        bottom: 0;
        right: 0;
        width: auto;
        left: auto;
        margin-right: 0px;
        line-height: 1;
        min-width: 48px;
        span {
          font-size: 12px;
          line-height: 14.4px;
        }
      }
      .swiper-navigation {
        font-size: 0;
      }
      .swiper-button-next-2,
      .swiper-button-prev-2 {
        width: 48px;
        height: 48px;
        margin-left: 3px;
      }
    }
  }
  .mobileSwiperControlsWrap {
    display: flex;
    .mobileSwiperControls {
      .fancySwiperControls {
        .swiper-button-next-2,
        .swiper-button-prev-2 {
          width: 48px;
          height: 48px;
          margin-left: 4px;
        }
      }
    }
  }
}
.disclaimerPopover {
  .themeLink {
    font-size: 14px;
    line-height: 16.8px;
    font-weight: 600;
    color: $kiaPolarWhite;
    text-transform: capitalize;
    display: inline-block;
    position: relative;
    &:hover {
      color: $kiaPolarWhite;
    }
    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      width: 0;
      background-color: $kiaPolarWhite;
      transition: width 0.3s;
    }
    &:hover::before,
    &:focus::before,
    &:active::before {
      width: 100%;
      transition: width 0.3s;
    }
    .menuIcon {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: auto;
      right: -18px;
      z-index: 1;
      margin-top: -8px;
      transition: all ease-in-out 0.3s;
    }
    &:hover {
      text-decoration: none !important;
    }
  }
  .disclaimerPopoverContent {
    color: $kiaMidnightBlack;
  }
}
